function scrollAnimate () {

    var doAnimations = function() {
        
        // Calc current offset and get all animatables
        var offset = $(window).scrollTop() + $(window).height(),
            $animatables = $('.animatable');
        
        // Unbind scroll handler if we have no animatables
        if ($animatables.length == 0) {
        $(window).off('scroll', doAnimations);
        }
        
        // Check all animatables and animate them if necessary
            $animatables.each(function(i) {
        var $animatable = $(this);
                if (($animatable.offset().top + $animatable.height() - 20) < offset) {
            $animatable.removeClass('animatable').addClass('animated');
                }
        });

        };
    
    // Hook doAnimations on scroll, and trigger a scroll
        $(window).on('scroll', doAnimations);
    $(window).trigger('scroll');
}

module.exports = scrollAnimate;